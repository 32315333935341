.camp-conatiner {
  padding: 30px;
  margin-bottom: 20px;
}
.camp-item1 {
  padding: 0px 10px;
}

.camp-head {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  margin-top: 107px;
  font-size: 53px;
  line-height: 78px;
  color: #282c35;
}
.camp-para {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #282c35;
}
