.whats-container {
  display: flex;
  height: 804px;
}
.brands-heading {
  padding: 7px 22px 25px 78px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 90px;
  color: #3f3f41;
}
.box-1 {
  height: 100px;
  width: 106px;
  margin-left: 197px;
}
.box-2 {
  height: 106px;
  width: 106px;
  margin-left: 205px;
}
.box-3 {
  height: 118.6px;
  width: 106px;
  margin-left: 201px;
  margin-top: -12px;
}
.box-4 {
  height: 104.21px;
  width: 106px;
  margin-left: 205px;
}
.box-5 {
  height: 110px;
  width: 106px;
  margin-left: 205px;
  object-fit: contain;
}
.box-6 {
  height: 118.6px;
  width: 106px;
  margin-left: 201px;
  margin-top: -12px;
}
.brand-head {
  display: flex;
  justify-content: center;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #423230;
}
.brand-para {
  display: flex;
  margin-left: 89px;
  width: 342px;
  height: 107px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #282c35;
  margin-top: -19px;
}
/* .brand-para2{
    display: flex;
    margin-left: 89px;
    width: 342px;
    height: 107px;
    font-family: 'Amaranth';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #282C35;
    margin-top: -15px;
} */
