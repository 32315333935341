.main-content {
  margin: 0 auto;
  width: 633px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
.main-content-1 {
  margin: 0 auto;
  width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
.label-contact {
  cursor: pointer;
}
.main-content-excluded-2 {
  position: absolute;
  top: 0;
  left: -1000px;
  margin-top: 30px;
  width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: "Amaranth";
  justify-content: center;
}

@keyframes seconds2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-content2 {
  position: absolute;
  top: 0;
  margin-top: 30px;
  width: 580px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Amaranth";
  justify-content: center;
  transition: transform 2s linear;
  transform: translateX(150px);
  /* animation: seconds2 5s; */
}

.contact-button {
  position: absolute;
  top: 550px;
  left: -1000px;
}

.contact-button-appear {
  transition: transform 3s ease;
  transform: translateX(1000px);
}

@media only screen and (min-width: 1440px) {
  .contact-button {
    top: 550px;
  }
}

@media only screen and (min-height: 982px) {
  .contact-button {
    top: 650px;
  }
}

.no-learn-more {
  position: absolute;
  left: -600;
}

.learn-more {
  position: relative;
  transition: transform 3s ease;
  transform: translateX(0px);
}

@keyframes seconds {
  0% {
  }
  100% {
    left: -9999px;
  }
}

.mainMove {
  transition: transform 3s linear;
  transform: translateX(-320px);
  /* animation: seconds 5s forwards; */
}
.mainMove1 {
  transition: transform 3s linear;
  transform: translateX(-50%);
}
.cards-main-div {
  height: 100%;
}

.column-card-one {
  position: relative;
  top: 720px;
}
.column-card-two {
  position: relative;
  bottom: 2700px;
}
@keyframes marquee {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-300%);
  }
}
.vertical-cards1 {
  transition: transform 3s ease;
  transform: translateY(-1532px);
  /* animation: marquee 3s infinite; */
}
.vertical-cards2 {
  transition: transform 3s ease;
  transform: translateY(2070px);
}

.vertical-card {
  max-width: 312px;
  max-height: 503px;
  border-radius: 30px;
  margin: 8px;
}

.vertical-card img {
  max-width: 312px;
  max-height: 503px;
  min-width: 311px;
  min-height: 502px;
  object-fit: cover;
  border-radius: 30px;
}

@media only screen and (min-height: 982px) {
  .cards-main-div {
    height: 100%;
  }
  .column-card-one {
    position: relative;
    top: 980px;
  }
  .column-card-two {
    position: relative;
    bottom: 2500px;
  }
  .vertical-cards1 {
    transition: transform 5s ease;
    transform: translateY(-1540px);
  }
  .vertical-cards2 {
    transition: transform 5s ease;
    transform: translateY(2070px);
  }
}

.main-heading {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 88px;
  letter-spacing: 0.05em;
  margin: 0px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 2px 7px rgba(255, 255, 255, 0.34);
}
.main-heading-short {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 51px;
  margin: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 7px rgba(255, 255, 255, 0.34);
}

.main-heading-about {
  line-height: 29px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 7px rgba(255, 255, 255, 0.34);
}

.main-heading2 {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  margin: 0px;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: start;
  color: #ffffff;
  text-shadow: 0px 2px 7px rgba(255, 255, 255, 0.34);
}

.main-para {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.main-para2 {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  text-align: start;
  color: #ffffff;
}

.main-para-fit {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  color: #f2f2f2;
}
.main-para-short {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  color: #f2f2f2;
}
.main-para-about {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #f2f2f2;
}
.main-heading-fit {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 53px;
  line-height: 68px;
  margin: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 7px rgba(255, 255, 255, 0.34);
}
.main-head-cowork {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 68px;
  margin: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 7px rgba(255, 255, 255, 0.34);
}
.learn-more1 {
  cursor: pointer;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  padding: 11px 35px 8px;
  gap: 10px;
  background: #ffffff;
  border-radius: 6px;
  border: none;
  color: #0064f4;
}

video {
  height: 100%;
  width: 1440px;
}

.vide0-bg {
  width: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
  top: 0;
  left: 0;
}
.food-span-food {
  padding-right: 504px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span-fit {
  padding-right: 490px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span {
  padding-right: 403px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span-short {
  width: 293px;
  padding-right: 337px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span-salon {
  padding-right: 571px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span-office {
  padding-right: 507px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span-cafe {
  padding-right: 436px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}
.food-span-about {
  padding-right: 51px;
  margin-right: 498px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #f2f2f2;
}

#marquee {
  /* padding: 50px 0px; */

  width: 100%;
}

.ani {
  animation: marqueey 60s infinite linear;
}

#marquee .container {
  overflow: hidden;
  width: 100%;
}

#marquee .pic-container {
  display: flex;
  flex-direction: column;
}

/* #marquee .pic-container .pic {
  flex-basis: 20%;
  flex-shrink: 0;
} */

#marquee img {
  width: 311px;
  height: 502px;
  max-width: 312px;
  max-height: 503px;
  border-radius: 30px;
  margin: 8px;
  object-fit: cover;
  /* border: 1px solid rgba(238, 231, 231, 0.9); */
}

@media (max-width: 1024px) {
  #marquee .pic-container .pic {
    /* flex-basis: 25%; */
  }
}

@media (max-width: 768px) {
  #marquee .pic-container .pic {
    /* flex-basis: 33.33%; */
  }
}

@keyframes marqueey {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media (max-width: 1024px) {
  @keyframes marqueey {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-125%);
    }
  }
}

@media (max-width: 768px) {
  @keyframes marqueey {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-186.65%);
    }
  }
}

#marquee2 {
  padding: 50px 0px;
  height: 100%;
}

#marquee2 .container2 {
  overflow: hidden;
  width: 100%;
}

#marquee2 .pic-container2 {
  display: flex;
  flex-direction: column;
  animation: marq 60s infinite linear;
}

/* #marquee .pic-container .pic {
  flex-basis: 20%;
  flex-shrink: 0;
} */

#marquee2 img {
  width: 311px;
  height: 502px;
  max-width: 312px;
  max-height: 503px;
  border-radius: 30px;
  margin: 8px;
  object-fit: cover;
  /* border: 1px solid rgba(238, 231, 231, 0.9); */
}

@media (max-width: 1024px) {
  #marquee2 .pic-container2 .pic {
    /* flex-basis: 25%; */
  }
}

@media (max-width: 768px) {
  #marquee2 .pic-container2 .pic {
    /* flex-basis: 33.33%; */
  }
}

@keyframes marq {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  @keyframes marq {
    0% {
      transform: translateY(-125%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media (max-width: 768px) {
  @keyframes marq {
    0% {
      transform: translateY(-186.65%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
