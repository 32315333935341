.consumer-main {
  background: #303036;
  position: relative;
  padding: 71px;
  margin-top: 59px;
}

.consumer-items {
  display: flex;
  justify-content: space-around;
  margin-bottom: 53px !important;
}
.what-they {
  left: 118px;
  position: relative;
  top: -39px;
}
.para-1-1 {
  position: relative;
  left: 46px;
  margin-bottom: 0px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #f1f2f6;
}

.para-1 {
  position: relative;
  margin-bottom: 0px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #f1f2f6;
}

.span-1 {
  margin-top: 0px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #f1f2f6;
}
.consumer-content {
  padding: 3px 0px;
  position: absolute;
  top: -123px;
  width: 80%;
  min-height: 198px;
  background: #ffffff;
  box-shadow: 0px 13px 12px rgba(0, 100, 244, 0.1);
  border-radius: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 570;
  font-size: 48px;
  line-height: 68px;
}
.consumer-content-box {
  position: relative;
  display: flex;
  justify-content: center;
}
