.go-heading{
    display: flex;
    font-family: 'Amaranth';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 48px;
letter-spacing: 0.04em;
padding: 7px 22px 25px 78px;
color: #423230;
}