.globe-main{
    display: flex;
    justify-content: center;
}
.globe-heading{
    padding: 30px;
    font-family: 'Amaranth';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 78px;
    color: white;
}
.main-part{
    /* background: #262626; */
    margin-top: 16rem;
}

  a {
    color: royalblue;
  }