.products{
  margin-top: 100px;
    padding: 0px 61px;
    font-family: 'Amaranth';
font-style: normal;
font-weight: 400;
font-size: 64px;
line-height: 78px;
color: #303036;
}
.product-col {
    padding-bottom: 65px;
}

#carousel {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* Hide the scrollbar for MS Edge */
  scrollbar-width: none; /* Hide the scrollbar for Mozilla Firefox */
  margin: 0 auto 0 auto;
  width: 90vw;
  height: fit-content;
}

#carousel::-webkit-scrollbar {
    overflow: scroll;
  display: none; /* Hide the scrollbar on Webkit based browsers (Chrome, Safari, etc) */
  -webkit-overflow-scrolling: touch; /* On touch screens the content continues to scroll for a while after finishing the scroll gesture */
}

.item {
  cursor: pointer;
  min-width: 205px;
  font-family: 'Amaranth';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}