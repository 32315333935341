.privacy {
    margin-left: 91px;
    margin-top: 120px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 55px;
    color: #8638E5;
}

.priv2 {
    margin-top: 10px;
    margin-left: 130px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #282C35;
    margin-right: 76px;
}

.priv-1 {
    margin-left: 91px;
    margin-right: 76px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #282C35;
}