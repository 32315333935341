.caseHeading {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 78px;
  color: #303036;
  margin-left: 62px;
}
.main-case {
  display: flex;
  padding: 0px 50px 0px 70px;
}
.img-container {
  width: 416px;
  height: 333px;
  border-radius: 40px;
  border: 1px solid #f8f8ff;
}
.image-case {
  width: 416px;
  height: 333px;
}

.card-img {
  width: 416px;
  height: 333px;
  max-width: 416px;
  max-height: 333px;
  border-radius: 40px;
  object-fit: cover;
  border: "1px solid #f8f8ff";
}

@media only screen and (min-height: 982px) {
  .card-img {
    width: 416px;
    height: 333px;
    max-width: 400px;
    max-height: 333px;
    border-radius: 40px;
    border: "1px solid #f8f8ff";
  }
}

.cocacolaTag {
  /* text-align: center; */
  width: 415px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.04em;
  margin: 5px 0px 0px 12px;
  color: #282c35;
  height: 100px;
  overflow: hidden;
}
.cocacolaData {
  width: 390px;
  height: 90px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.04em;
  color: #282c35;
  padding-left: 12px;
}
.case2 {
  display: flex;
  justify-content: center;
}
.view-all {
  cursor: pointer;
  text-decoration: none;
  width: 87px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #4c9aff;
}
