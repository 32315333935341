.faq-conatiner{
    height: 455px;
    padding: 25px 0px 0px 0px;
    margin-left: 44px;
}
.faqs-heading{
    margin: 20px 8px;
font-family: 'Amaranth';
font-style: normal;
font-weight: 400;
font-size: 54px;
line-height: 74px;
color: #303036;
}
.MuiAccordion-root{
    box-shadow: none !important;
}
.MuiAccordion-region{
    margin-top: -40px;
}
