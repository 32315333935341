.brand-conatiner {
  display: flex;
  justify-content: space-between;
  background: #303036;
}
.brand-conatiner-about {
  margin-top: 228px;
  display: flex;
  justify-content: space-between;
  background: #303036;
}
.brands-heading {
  margin:0px;
  padding-left: 37px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 90px;
  color: #ffffff;
}
.lipton {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 90px;
  width: 170px;
  padding: 5px 12px 2px;
  margin-left: 53px;
}
.mentos {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding: 5px 12px 2px;
}
.heine {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 93px;
  width: 319px;
  padding: 5px 12px 2px;
}
.frame249 {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 71px;
  width: 82px;
  padding: 8px 15px 4px;
}
.colgate {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 139px;
  width: 398px;
  padding: 5px 12px 2px;
  margin-left: 53px;
}
.unilevr {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 62px;
  width: 170px;
  padding: 28px 12px 2px;
}
.redbull {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 106px;
  width: 381px;
  padding: 5px 12px 2px;
}
.nescafe {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 91px;
  width: 163px;
  padding: 5px 12px 2px;
}
.pepsi {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 95px;
  width: 173px;
  padding: 5px 60px 2px;
  margin-left: 40px;
}
.coco {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 95px;
  width: 173px;
  padding: 5px 12px 2px;
}
.rowdy {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 99px;
  width: 312px;
  padding: 5px 12px 2px;
}
.dare {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 95px;
  width: 173px;
  padding: 5px 12px 2px;
}
.royal {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 91px;
  width: 163px;
  padding: 5px 12px 2px;
}
.cadbuary {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 91px;
  width: 163px;
  padding: 5px 12px 2px;
}
.zespri {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding: 5px 20px 2px;
  margin-left: 53px;
}
.aperol {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 91px;
  width: 195px;
  padding: 5px 12px 2px;
}
.topricon {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 66px;
  width: 150px;
  padding: 5px 0px 2px;
}
.pg {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding: 5px 12px 2px;
}
.barista {
  animation: blowing 5s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding: 5px 12px 2px;
}
.sprite {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding: 5px 12px 2px;
}
.danone {
  animation: blowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding-top: 5px;
}

@keyframes glowing {
  0% {
    opacity: 0.1;
  }
  /* 20% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  } */
  100% {
    opacity: 1;
  }
}
@keyframes blowing {
  0% {
    opacity: 1;
  }

  /* 40% {
    opacity: 0.5;
  } */
  100% {
    opacity: 0.1;
  }
}
.kellogos {
  animation: glowing 4s ease infinite alternate;
  border-radius: 39px;
  margin-right: 33px;
  height: 88px;
  width: 150px;
  padding: 5px 12px 2px;
}
