.service-heading {
  padding: 0px 60px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  color: #303036;
  padding-left: 120px;
}
.service-container {
  padding-left: 90px;
  padding-right: 50px;
}

.service-container2 {
  padding-left: 120px;
  padding-right: 50px;
}

@media only screen and (min-height: 982px) {
  .service-container {
    padding-left: 110px;
    padding-right: 30px;
  }
  .service-container2 {
    padding-left: 100px;
    padding-right: 50px;
  }
  .service-heading {
    padding-left: 100px;
  }
}

.ser-head {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  padding: 8px 6px 6px 0px;
}
.sec-para {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
