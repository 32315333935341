.sample-heading {
  padding: 0px 73px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  color: #303036;
  /* padding-left: 120px; */
}
.pad {
  padding-left: 40px;
}

.sample-para {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #2e3137;
  padding-left: 80px;
}

.margins {
  margin-left: 50px;
}
@media only screen and (min-height: 982px) {
  .margins {
    margin-left: 20px;
  }
  .pad {
    padding-left: 20px;
  }
  /* .sample-para {
    padding-left: 100px;
  }
  .sample-heading {
    padding-left: 100px !important;
  } */
}
.sampling-card {
  width: 124px;
  height: 502px;
  /* transform: rotate(-90deg); */
  background-image: url(../../assests/blur.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 25px;
  /* backdrop-filter: blur(2em); */
  /* box-shadow: inset 24.4px -24.4px 24.4px rgba(194, 194, 194, 0.1),
    inset -24.4px 24.4px 24.4px rgba(255, 255, 255, 0.1); */
}

.sampling-card-lab {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/Samp3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
  display: flex;
  align-items: end;
}

.sampling-card-lab1 {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/students.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
}

.sampling-card-lab2 {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/Samp2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
  display: flex;
  align-items: end;
}

.sampling-card-lab-airbnb {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/Samp4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
}

.sampling-card-lab3 {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/samp1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
  display: flex;
  align-items: end;
}

.sampling-card-lab4 {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/saloonGirl.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
}

.sampling-card-lab5 {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/cream.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
}

.sampling-card-lab-saloon {
  width: 725px;
  height: 502px;
  background-image: url(../../assests/Samp5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
}

.blurred-card {
  width: 560px;
  height: 132px;
  font-size: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: "#282C35";
  border-radius: 25px;
  padding: 6px 20px 6px 30px;
  /* transform: rotate(-90deg); */
  background-image: url(../../assests/blurryCard.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.blurred-card-air {
  width: 560px;
  height: 132px;
  font-size: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: "#282C35";
  border-radius: 25px;
  padding: 6px 20px 6px 30px;
  /* transform: rotate(-90deg); */
  background-image: url(../../assests/blur-card.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.blurred-card-fitness {
  width: 560px;
  height: 132px;
  font-size: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: "#282C35";
  border-radius: 25px;
  padding: 6px 20px 6px 30px;
  /* transform: rotate(-90deg); */
  background-image: url(../../assests/fitnessblur.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
