.head1 {
  display: flex;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: 0.04em;
  padding: 7px 22px 25px 78px;
  color: #423230;
}
.sample-cont{
  padding-left: 32px;
}
.sample-main {
  display: flex;
  padding: 0px 49px 10px 40px;
}
.sample-div {
  width: 328px;
  height: 250px;
  background: rgba(0, 100, 244, 0.1);
  margin-right: 12px;
  border-radius: 40px;
}
.sample-div-expander {
  width: 328px;
  height: 250px;
  background: rgba(0, 100, 244, 0.1);
  margin-right: 7px;
  border-radius: 40px;
}
.sample-div-expanded {
  width: 647px;
  height: 250px;
  background: rgba(0, 100, 244, 0.1);
  margin-right: 7px;
  border-radius: 40px;
}
.sample-head1 {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282c35;
  margin-top: -17px;
}

.sample-head-expander {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  letter-spacing: 0.04em;
  color: #282c35;
  padding-top: 30px;
  padding-left: 28px;
}

.sample-para1 {
  width: 278px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282c35;
  display: flex;
  padding: 2px 20px;
}

.sample-para-expander {
  width: 278px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #282c35;
  padding: 2px 30px;
}

.sample-para-expanded {
  width: 578px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #282c35;
  padding: 2px 30px;
}

.img1-sampl {
  position: relative;
  background: white;
  margin-left: 132px;
  border-radius: 40px;
  padding: 5px;
  bottom: 27px;
}
