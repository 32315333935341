.groupFooter1 {
  width: 100%;
  left: 0px;
  height: 311px;
  background: #303036;
}
.geezyLogo {
  cursor: pointer;
}

.groupFooter2 {
  padding: 10px 29px 15px 31px;
  left: 45px;
}

.groupFooter3 {
  padding: 18px 31px 19px 0px;
  left: 400px;
  min-width: 220px;
}

.groupSolutions {
  text-decoration: none;
  font-style: "Inter";
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #f1f2f6;
  padding-bottom: 80px;
}

.groupFitness {
  font-style: "Inter";
  position: relative;
  top: 37px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupFood {
  font-style: "Inter";
  position: relative;
  top: 22px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupRentals {
  font-style: "Inter";
  position: relative;
  top: 12px;
  /* top:31px; */
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupSalons {
  position: relative;
  top: 31px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupOffices {
  font-style: "Inter";
  position: relative;
  top: 31px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupHotel {
  position: relative;
  top: 43px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
  font-style: "Inter";
}

.groupFooter4 {
  padding: 18px 31px 19px 0px;
  left: 661px;
  min-width: 110px;
}

.groupResources {
  text-decoration: none;
  font-style: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #f1f2f6;
}

.groupAbout {
  font-style: "Inter";
  position: relative;
  top: 12px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupCase {
  font-style: "Inter";
  position: relative;
  top: 22px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupBlogs {
  font-style: "Inter";
  position: relative;
  top: 31px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupFAQ {
  font-family: "Inter";
  position: relative;
  top: 37px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupFooter5 {
  padding: 18px 31px 19px 0px;
  min-width: 200px;
}

.groupLegal {
  font-style: "Inter";
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #f1f2f6;
}

.groupTerms {
  font-style: "Inter";
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupPrivacy {
  font-style: "Inter";
  position: relative;
  top: 10px;
  text-decoration: none;
  font-weight: 400;
  color: #d5c6c6;
}

.groupFooter6 {
  padding: 19px 0px;
}
.socialFacebook {
  margin-left: 304px;
}

.img1 {
  border-radius: 12px;
  padding: 2px;
  width: 19.01px;
  height: 17.89px;
  background-color: white;
}
.img2 {
  margin-left: 5px;
  background-color: white;
  border-radius: 6px;
  padding: 2px 2px 2px 2px;
  width: 19.01px;
  height: 17.01px;
}
.img3 {
  margin-left: 5px;
  background-color: white;
  border-radius: 6px;
  padding: 2px 2px 2px 2px;
  width: 19.01px;
  height: 17.89px;
}

.footerDivider {
  margin-top: 45px;
  display: flex;
  justify-content: end;
  margin-left: 40px;
  margin-right: 90px;
  border-top: 1px solid #bababa;
}
.footer-version {
  font-family: "Inter";
  display: flex;
  justify-content: end;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.main-box {
  padding: 32px;
}
.main-box .MuiButtonBase-root {
  box-shadow: none;
}

.margs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.reach-footer {
  background: #303036;
  margin-top: 32px;
  margin-bottom: 56px !important;
}
.reach-footer-para {
  padding: 23px;
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #f1f2f6;
}
