ul li {
  list-style: none;
}

.nav-links li {
  padding: 0 15px;
}

.nav-links a {
  text-decoration: none;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #f1f2f6;
}

.geezy-header {
  background: rgba(48, 48, 54, 0.6);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.label-contact {
  width: 119px;
  height: 20px;
  cursor: pointer;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0064f4;
}

.contactUs-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 11px 36px 11px 39px;
  background: #ffffff;
  border-radius: 6px;
  border: none;
}
.contactUs-btn1 {
  margin-left: 40px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 11px 36px 11px 39px;
  background: #ffffff;
  border-radius: 6px;
  border: none;
}

.arrow-icon {
  margin: -8px;
  padding-left: 12px;
  background: rgba(255, 255, 255, 0.0001);
}

.menu-main ul {
  margin-top: 23px;
  position: relative;
}

.MuiPaper-root {
  cursor: pointer;
}

.items-menu:hover {
  background-color: #0064f4 !important;
}

.items-menu {
  font-family: "Inter";
}
