.gal-heading {
    margin-top: 219px;
    display: flex;
    font-family: 'Amaranth';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.04em;
    padding: 7px 22px 25px 78px;
    color: #423230;
}

.gal-12-div {
    display: flex;
    margin-left: 44px;
}

.gal-div1 {
    position: relative;
    top: 79px;
}


