.contentHeading {
  font-family: "Amaranth";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 78px;
  /* identical to box height, or 144% */

  letter-spacing: 0.04em;
  color: #282c35;
}
.contentDate {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */
  letter-spacing: 0.04em;
  color: #282c35;
}
.contentData {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  color: #282c35;
}
.group2769 {
  /* border: 1px solid #f8f8ff; */
  border-radius: 40px;
  display: flex;
  margin-top: 37px;
}
.contentText1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: #282c35;
}
.contextText2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: #282c35;
}
.contextText3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: #282c35;
}
.group2770 {
  /* border: 1px solid #F8F8FF; */
  border-radius: 40px;
  display: flex;
  margin-top: 10px;
}
